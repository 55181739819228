import ColorBox from 'components/shared/colorBox';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import { ReactComponent as CourseIcon } from '../../../assets/icons/courses-icon-dark.svg';
import { ReactComponent as CourseLevelIcon } from '../../../assets/icons/course-level-icon.svg';
import { ReactComponent as AttributeIcon } from '../../../assets/icons/checklist-icon.svg';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
  validateUrl,
  validateFileType,
  validateFileSize,
} from '../../../utility/validation';
import { filterTags } from '../../../utility/tagUtils';
import http from '../../../utility/http';
import {
  asyncDebounce,
  isArrayEmpty,
  trimString,
} from '../../../utility/helpers';
import { ALL } from '../../../constants/courses';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { PARAMS } from '../../../constants/pages';
import { PHOTO_UPLOAD_CONFIG } from '../../../constants/uploadPhotoConfig';
import { CREATABLE_TAG_FIELD } from '../../../constants/tags';
import { API_UNIQUE_COURSE_TITLE } from '../../../constants/apiRoutes';

const { ADD_IMAGE, SELECT, TEXT } = FIELD_TYPES;

export const INITIAL_ADD_COURSE_DATA = {
  title: '',
  search: '',
  attribute: null,
  tags: [],
  level: ALL.id,
  selectedCourse: null,
  url: '',
  description: '',
  cover_image: null,
};

export const getPageFilters = (
  translations,
  levels,
  attributes,
  tagCategories,
  quickFilters = []
) => {
  const { LEVEL, ATTRIBUTE, TAGS } = PARAMS;
  const defaultOption = { id: null, name: translations.all };

  return [
    {
      id: LEVEL,
      name: translations.filters.labels[LEVEL],
      icon: CourseLevelIcon,
      isSingleSelect: true,
      items: [defaultOption, ...levels],
    },
    {
      id: ATTRIBUTE,
      name: translations.filters.labels[ATTRIBUTE],
      icon: AttributeIcon,
      isSingleSelect: true,
      hasSubmenuColorBox: true,
      items: [defaultOption, ...attributes],
    },
    {
      id: TAGS,
      name: translations.filters.labels[TAGS],
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            parent: {
              id: TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};

export const getCourseFields = onCreateTag => [
  {
    name: 'title',
    type: TEXT,
    required: true,
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
      {
        type: 'uniqueTitle',
        validator: asyncDebounce(courseTitle => {
          const params = {
            title: trimString(courseTitle),
          };
          return http
            .get(API_UNIQUE_COURSE_TITLE, { params })
            .then(({ data }) => !data);
        }, 300),
        async: true,
      },
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
      {
        type: 'titleMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 500),
      },
    ],
  },
  {
    name: 'level',
    type: SELECT,
    optionsKey: 'levels',
    parser: {
      value: 'id',
      label: 'name',
    },
  },
  {
    name: 'url',
    type: TEXT,
    required: true,
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
      { type: 'invalidUrl', validator: validateUrl },
    ],
  },
  {
    name: 'description',
    type: TEXT,
    multiline: true,
    rows: 5,
    validators: [
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
      {
        type: 'descriptionMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 2500),
      },
    ],
  },
  {
    name: 'attribute',
    type: SELECT,
    optionsKey: 'allAttributes',
    hasColorBox: true,
    isClearable: true,
    parser: {
      value: 'id',
      label: 'name',
    },
  },
  {
    name: 'tags',
    type: SELECT,
    optionsKey: 'categories',
    creatableOptionField: CREATABLE_TAG_FIELD,
    isMulti: true,
    hasHash: true,
    hasCategoryColorBox: true,
    isCreatable: true,
    isCategorized: true,
    isTag: true,
    parser: {
      value: 'id',
      label: 'name',
      categoryLabel: 'name',
      categoryValue: 'tags',
    },
    onCreateOption: onCreateTag,
  },
  {
    name: 'cover_image',
    type: ADD_IMAGE,
    translationKey: 'courseImage',
    validators: [
      {
        type: 'fileType',
        validator: value =>
          validateFileType(value, Object.keys(PHOTO_UPLOAD_CONFIG.mimeTypes)),
      },
      {
        type: 'fileSize',
        validator: value =>
          validateFileSize(value, PHOTO_UPLOAD_CONFIG.maxSize),
      },
    ],
  },
];

export const getCourseActions = (
  labels,
  onViewDetails,
  onEdit,
  onDelete,
  shouldRender
) => [
  {
    action: onViewDetails,
    label: labels.viewDetails,
    shouldItemRender: () => true,
    type: 'Details',
    icon: CourseIcon,
  },
  {
    action: onEdit,
    label: labels.edit,
    shouldItemRender: () => shouldRender,
    type: 'Enroll',
    icon: EditIcon,
  },
  {
    action: onDelete,
    label: labels.deleteAction,
    shouldItemRender: () => shouldRender,
    type: 'Delete',
    icon: DeleteIcon,
  },
];
