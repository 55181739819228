import http from '../../../utility/http';
import {
  isEmpty,
  forbiddenCharacters,
  validateLength,
  validateRequiredMultipleDates,
  validateUniqueMultipleDates,
} from '../../../utility/validation';
import {
  formatDate,
  getStartOfYear,
  getEndOfYear,
} from '../../../utility/dateUtils';
import { asyncDebounce, trimString } from '../../../utility/helpers';
import { absenceTypeHasAditionalSettingsCheck } from '../../../utility/absence';
import { SINGLE_DAY_SAVE_FORMAT } from '../../../constants/date';
import {
  ABSENCE_TYPES_KEYS,
  ACCRUAL_TYPES_KEYS,
} from '../../../constants/absence';
import { API_UNIQUE_ABSENCE_SET } from '../../../constants/apiRoutes';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { getUserStatusLabel } from '../../../utility/uiUtils';

export const usersField = translations => {
  return [
    {
      name: 'users',
      type: FIELD_TYPES.PEOPLE_PICKER,
      translationKey: 'users',
      optionsKey: 'allUsers',
      showSelection: true,
      hasSelectAll: true,
      shouldHandleTypeLabel: true,
      handleTypeLabel: employee =>
        getUserStatusLabel(translations.absenceSets.deactivated, employee),
    },
  ];
};

export const getAssignUsersInitialData = users => ({
  users,
});

export const getHolidayInitialData = (holiday = null) => ({
  name: holiday?.name || '',
  dates: holiday?.dates || [null],
  ...(holiday ? { id: holiday.id } : {}),
});

export const getManageHolidayFields = currentYear => [
  {
    name: 'name',
    type: FIELD_TYPES.TEXT,
    required: true,
    shouldValidateInitialValue: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
      {
        validator: forbiddenCharacters,
        type: 'forbiddenCharacters',
      },
      {
        validator: fieldValue => validateLength(fieldValue, 0, 255),
        type: 'nameMaxLength',
      },
    ],
  },
  {
    name: 'dates',
    type: FIELD_TYPES.MULTIPLE_DATE_PICKER,
    translationKey: 'dates',
    getMinDate: () => getStartOfYear(),
    getMaxDate: () => new Date(currentYear + 1, 11, 31),
    validators: [
      {
        validator: validateRequiredMultipleDates,
        type: 'required',
        isMultiple: true,
      },
      {
        validator: validateUniqueMultipleDates,
        type: 'uniqueDate',
        isMultiple: true,
      },
    ],
  },
];

export const getAbsenceSetInitialData = (set = null) => ({
  id: set?.id || null,
  name: set?.name || '',
  user_count: set?.user_count || 0,
});

export const getManageAbsenceSetFields = () => [
  {
    name: 'name',
    type: FIELD_TYPES.TEXT,
    required: true,
    shouldValidateInitialValue: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
      {
        validator: forbiddenCharacters,
        type: 'forbiddenCharacters',
      },
      {
        validator: fieldValue => validateLength(fieldValue, 0, 255),
        type: 'nameMaxLength',
      },
      {
        type: 'availableName',
        validator: asyncDebounce(absenceSet => {
          const params = {
            name: trimString(absenceSet),
          };
          return http
            .get(API_UNIQUE_ABSENCE_SET, { params })
            .then(({ data }) => !data);
        }, 300),
        async: true,
      },
    ],
  },
];

export const getManageAbsenceTypeInitialData = (absenceType, usedTypes = []) =>
  absenceType
    ? {
        ...absenceType,
        restart_balance_date:
          absenceType.restart_balance_date ||
          formatDate(new Date(), SINGLE_DAY_SAVE_FORMAT),
      }
    : {
        type:
          Object.keys(ABSENCE_TYPES_KEYS).find(key =>
            usedTypes.every(usedType => usedType.type !== key)
          ) || ABSENCE_TYPES_KEYS.VACATION_LEAVE,
        accrual: ACCRUAL_TYPES_KEYS.YEARLY,
        is_roll_over: false,
        number_of_days: 20,
        restart_balance_date: formatDate(new Date(), SINGLE_DAY_SAVE_FORMAT),
      };

export const getManageAbsenceTypeFields = (classes, isEdit) => [
  {
    name: 'type',
    type: FIELD_TYPES.SELECT,
    translationKey: 'type',
    required: true,
    optionsKey: 'absenceTypes',
    parser: {
      value: 'id',
      label: 'name',
    },
    isDisabled: isEdit,
  },
  {
    name: 'accrual',
    type: FIELD_TYPES.SELECT,
    translationKey: 'accrual',
    required: true,
    optionsKey: 'accrualTypes',
    isToggleable: true,
    shouldRender: values => absenceTypeHasAditionalSettingsCheck(values.type),
    parser: {
      value: 'value',
      label: 'name',
    },
  },
  {
    name: 'number_of_days',
    type: FIELD_TYPES.TEXT,
    inputType: 'number',
    translationKey: 'numberOfDays',
    customRootClass: classes.numberOfDaysField,
    isToggleable: true,
    shouldRender: values => absenceTypeHasAditionalSettingsCheck(values.type),
    inputProps: {
      min: 1,
      max: 365,
    },
    required: true,
    validators: [
      { type: 'required', validator: isEmpty },
      {
        type: 'minMaxDays',
        validator: value => value >= 1,
      },
    ],
  },
  {
    name: 'is_roll_over',
    type: FIELD_TYPES.SWITCH,
    translationKey: 'rollOver',
    isToggleable: true,
    shouldRender: values =>
      absenceTypeHasAditionalSettingsCheck(
        values.type,
        values.type === ABSENCE_TYPES_KEYS.VACATION_LEAVE
      ),
  },
  {
    name: 'restart_balance_date',
    type: FIELD_TYPES.DATE_PICKER,
    translationKey: 'restartBalance',
    valueFormat: SINGLE_DAY_SAVE_FORMAT,
    isToggleable: true,
    getMinDate: () => getStartOfYear(),
    getMaxDate: () => getEndOfYear(),
    shouldRender: values =>
      absenceTypeHasAditionalSettingsCheck(
        values.type,
        values.is_roll_over && values.type === ABSENCE_TYPES_KEYS.VACATION_LEAVE
      ),
  },
];

export const getManageExtensionInitialData = extension =>
  extension || {
    name: '',
    description: '',
    number_of_days: 1,
  };

export const getManageExtensionFields = classes => [
  {
    name: 'name',
    type: FIELD_TYPES.TEXT,
    required: true,
    shouldValidateInitialValue: true,
    validators: [
      {
        type: 'required',
        validator: isEmpty,
      },
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
      {
        type: 'nameMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 255),
      },
    ],
  },
  {
    name: 'description',
    type: FIELD_TYPES.TEXT,
    multiline: true,
    rows: 5,
    validators: [
      {
        type: 'forbiddenCharacters',
        validator: forbiddenCharacters,
      },
      {
        type: 'descriptionMaxLength',
        validator: fieldValue => validateLength(fieldValue, 0, 255),
      },
    ],
  },
  {
    name: 'number_of_days',
    type: FIELD_TYPES.TEXT,
    inputType: 'number',
    translationKey: 'numberOfDays',
    customRootClass: classes.numberOfDaysField,
    inputProps: {
      min: 1,
      max: 365,
    },
    required: true,
    validators: [
      { type: 'required', validator: isEmpty },
      {
        type: 'minMaxDays',
        validator: value => value >= 1,
      },
    ],
  },
];

export const prepareAssignUserOptions = (allUsers, assignedUsers = []) =>
  allUsers.map(user => {
    const assignedUser = assignedUsers.find(aUser => aUser.id === user.id);

    return {
      ...user,
      ...(assignedUser
        ? { ...assignedUser, isDisabled: assignedUser.has_absence_requests }
        : {}),
    };
  });

export const prepareAssignedUsers = assignedUsers => {
  if (!assignedUsers) return [];

  return assignedUsers.map(user => ({
    ...user,
    isDisabled: user.has_absence_requests,
  }));
};
