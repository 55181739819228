import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import AbsenceSet from './absenceSet';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
  },
  set: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const AbsenceSets = ({
  translations,
  sets,
  onEditSet,
  onDuplicateSet,
  onDeleteSet,
  onGetSetUsers,
  onAssignSetUsers,
  onAddType,
  onEditType,
  onDeleteType,
  onGetTypeUsers,
  onAssignTypeUsers,
  onAddExtension,
  onEditExtension,
  onDeleteExtension,
  onGetExtensionUsers,
  onAssignExtensionUsers,
  onAddHoliday,
  onEditHoliday,
  onDeleteHoliday,
  onGoToProfilePage,
}) => {
  const classes = useStyles();
  const isExpanded = sets?.length === 1;

  return (
    <TransitionGroup className={classes.root}>
      {sets.map(set => (
        <Fade
          key={`absence_set_${set.id}`}
          className={classes.set}
          in
          unmountOnExit
        >
          <div>
            <AbsenceSet
              translations={translations}
              set={set}
              onEditSet={onEditSet}
              onDeleteSet={onDeleteSet}
              onDuplicateSet={onDuplicateSet}
              onGetSetUsers={onGetSetUsers(set.id)}
              onAssignSetUsers={onAssignSetUsers(set)}
              onAddType={onAddType(set)()}
              onEditType={onEditType}
              onDeleteType={onDeleteType}
              onGetTypeUsers={onGetTypeUsers}
              onAssignTypeUsers={onAssignTypeUsers(set.id)}
              onAddExtension={onAddExtension}
              onEditExtension={onEditExtension}
              onDeleteExtension={onDeleteExtension}
              onGetExtensionUsers={onGetExtensionUsers}
              onAssignExtensionUsers={onAssignExtensionUsers}
              onAddHoliday={onAddHoliday(set)}
              onEditHoliday={onEditHoliday}
              onDeleteHoliday={onDeleteHoliday}
              onGoToProfilePage={onGoToProfilePage}
              isAutomaticallyExpanded={isExpanded}
            />
          </div>
        </Fade>
      ))}
    </TransitionGroup>
  );
};

AbsenceSets.defaultProps = {
  sets: [],
};

AbsenceSets.propTypes = {
  translations: PropTypes.object.isRequired,
  sets: PropTypes.arrayOf(PropTypes.object),
  onEditSet: PropTypes.func.isRequired,
  onDuplicateSet: PropTypes.func.isRequired,
  onDeleteSet: PropTypes.func.isRequired,
  onGetSetUsers: PropTypes.func.isRequired,
  onAssignSetUsers: PropTypes.func.isRequired,
  onAddType: PropTypes.func.isRequired,
  onEditType: PropTypes.func.isRequired,
  onDeleteType: PropTypes.func.isRequired,
  onGetTypeUsers: PropTypes.func.isRequired,
  onAssignTypeUsers: PropTypes.func.isRequired,
  onAddExtension: PropTypes.func.isRequired,
  onEditExtension: PropTypes.func.isRequired,
  onDeleteExtension: PropTypes.func.isRequired,
  onGetExtensionUsers: PropTypes.func.isRequired,
  onAssignExtensionUsers: PropTypes.func.isRequired,
  onAddHoliday: PropTypes.func.isRequired,
  onEditHoliday: PropTypes.func.isRequired,
  onDeleteHoliday: PropTypes.func.isRequired,
  onGoToProfilePage: PropTypes.func.isRequired,
};

export default AbsenceSets;
