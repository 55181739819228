import { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import AbsenceSets from '../../shared/absenceSets';
import CustomButton from '../../shared/customButton';
import CustomFormDrawer from '../../shared/customFormDrawer';
import AlertDialog from '../../shared/alertDialog';
import NotificationCard from '../../shared/notificationCard';
import {
  getAllAbsenceSets,
  clearAllAbsenceSets,
} from '../../../store/modules/absenceSets';
import http from '../../../utility/http';
import { absenceSetsSelector } from '../../../store/selectors/absenceSetsSelector';
import { getObjectToNumberArray, isArrayEmpty } from '../../../utility/helpers';
import { useTranslations } from '../../../utility/useTranslations';
import { showSuccessMessage } from '../../../utility/uiUtils';
import {
  prepareAbsenceSetsData,
  getTranslatedAbsenceTypes,
  getTranslatedAccrualTypes,
  absenceTypeHasAditionalSettingsCheck,
} from '../../../utility/absence';
import {
  API_USERS_BASIC,
  API_ABSENCE_SETS,
  API_ABSENCE_TYPES,
  API_ABSENCE_EXTENSIONS,
  API_HOLIDAYS,
  api_holiday,
  api_absence_set,
  api_absence_set_duplicate,
  api_absence_set_users,
  api_absence_type,
  api_absence_type_users,
  api_absence_extension,
  api_absence_extension_users,
} from '../../../constants/apiRoutes';
import { USER_STATUSES } from '../../../constants/statuses';
import { PEOPLE_DEFAULT_ORDERING } from '../../../constants/people';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { APP_PAGES, PARAMS } from '../../../constants/pages';
import { sticky } from '../../../constants/helperCssRules';
import {
  getHolidayInitialData,
  getManageHolidayFields,
  getAbsenceSetInitialData,
  getManageAbsenceSetFields,
  getAssignUsersInitialData,
  getManageAbsenceTypeFields,
  getManageAbsenceTypeInitialData,
  getManageExtensionInitialData,
  getManageExtensionFields,
  prepareAssignUserOptions,
  prepareAssignedUsers,
  usersField,
} from './config';
import { ABSENCE_TYPES_KEYS } from '../../../constants/absence';
import { formatDate } from '../../../utility/dateUtils';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
  },
  header: {
    ...sticky(primary.white, 105),
    padding: spacing(8, 0, 6, 0),
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  numberOfDaysField: {
    width: 75,
  },
}));

const AbsenceSettingsPage = ({ navigate, dispatch, ...rest }) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.ABSCENCE_MANAGEMENT);
  const currentYear = +formatDate(new Date(), 'yyyy');

  const absenceSets = useSelector(absenceSetsSelector);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isManageHolidayOpened, setIsManageHolidayOpened] = useState(false);
  const [holiday, setHoliday] = useState(null);
  const [isDeleteHolidayOpened, setIsDeleteHolidayOpened] = useState(false);
  const [isManageSetOpened, setIsManageSetOpened] = useState(false);
  const [isDuplicateSetOpened, setIsDuplicateSetOpened] = useState(false);
  const [isDeleteSetOpened, setIsDeleteSetOpened] = useState(false);
  const [isDeleteSetWarningOpened, setIsDeleteSetWarningOpened] =
    useState(false);
  const [isAssignSetUsersOpened, setIsAssignSetUsersOpened] = useState(false);
  const [absenceSet, setAbsenceSet] = useState(null);
  const [absenceSetUsers, setAbsenceSetUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [isManageAbsenceTypeOpened, setIsManageAbsenceTypeOpened] =
    useState(false);
  const [absenceType, setAbsenceType] = useState(null);
  const [isDeleteTypeOpened, setIsDeleteTypeOpened] = useState(false);
  const [isDeleteTypeWarningOpened, setIsDeleteTypeWarningOpened] =
    useState(false);
  const [isAssignTypeUsersOpened, setIsAssignTypeUsersOpened] = useState(false);
  const [absenceTypeUsers, setAbsenceTypeUsers] = useState([]);
  const [isManageExtensionOpened, setIsManageExtensionOpened] = useState(false);
  const [extension, setExtension] = useState(null);
  const [isDeleteExtensionOpened, setIsDeleteExtensionOpened] = useState(false);
  const [isDeleteExtensionWarningOpened, setIsDeleteExtensionWarningOpened] =
    useState(false);
  const [isAssignExtensionUsersOpened, setIsAssignExtensionUsersOpened] =
    useState(false);
  const [extensionUsers, setExtensionUsers] = useState([]);

  const absenceTypes = getTranslatedAbsenceTypes(
    translations.absenceTypes,
    translations.inUse,
    absenceSet?.types || []
  );
  const accrualTypes = getTranslatedAccrualTypes(translations.accrualTypes);
  const formatedSets = prepareAbsenceSetsData(
    translations,
    absenceSets,
    absenceTypes,
    accrualTypes
  );
  const manageHolidayInitialData = getHolidayInitialData(holiday);
  const manageHolidayFields = getManageHolidayFields(currentYear);

  const manageAbsenceSetInitialData = getAbsenceSetInitialData(absenceSet);
  const manageAbsenceSetFields = getManageAbsenceSetFields();
  const assignSetUsersInitialData = getAssignUsersInitialData(absenceSetUsers);

  const manageAbsenceTypeInitialData = getManageAbsenceTypeInitialData(
    absenceType,
    absenceSet?.types || []
  );
  const manageAbsenceTypeFields = getManageAbsenceTypeFields(
    classes,
    !!absenceType
  );
  const assignAbsenceTypeUsersInitialData =
    getAssignUsersInitialData(absenceTypeUsers);

  const manageExtensionInitialData = getManageExtensionInitialData(extension);
  const manageExtensionFields = getManageExtensionFields(classes);
  const assignExtensionUsersInitialData =
    getAssignUsersInitialData(extensionUsers);

  const handleGoToUserProfile = userId =>
    navigate(`/people/${userId}/absences`);

  const handleOpenManageHoliday = set => e => {
    e.stopPropagation();
    setAbsenceSet(set);
    setIsManageHolidayOpened(true);
  };

  const handleOpenEditHoliday = currentHoliday => {
    setHoliday(currentHoliday);
    setIsManageHolidayOpened(true);
  };

  const handleCloseManageHoliday = () => {
    setIsManageHolidayOpened(false);
    setAbsenceSet(null);
    setHoliday(null);
  };

  const onSaveHoliday = async ({ name, dates }) => {
    const data = {
      ...(!holiday ? { absence_set: absenceSet.id } : {}),
      name,
      dates,
    };
    if (holiday) {
      await http.patch(api_holiday(holiday.id), data);
      showSuccessMessage(translations.successMessages.editHoliday);
      return getAllAbsenceSets(dispatch);
    }

    await http.post(API_HOLIDAYS, data);
    showSuccessMessage(translations.successMessages.addHoliday);
    return getAllAbsenceSets(dispatch);
  };

  const handleOpenDeleteHoliday = currentHoliday => {
    setHoliday(currentHoliday);
    setIsDeleteHolidayOpened(true);
  };

  const handleCloseDeleteHolidayDialog = () => {
    setIsDeleteHolidayOpened(false);
  };

  const onDeleteHoliday = async () => {
    try {
      await http.delete(api_holiday(holiday.id));
      showSuccessMessage(translations.deleteHolidayDialog.deleteSuccess);
      getAllAbsenceSets(dispatch);
    } finally {
      setIsDeleteHolidayOpened(false);
      setHoliday(null);
      setIsManageHolidayOpened(false);
    }
  };

  const handleOpenManageSet = () => setIsManageSetOpened(true);

  const handleCloseManageSet = () => {
    setIsManageSetOpened(false);
    setAbsenceSet(null);
  };

  const handleOpenEditSet = set => {
    setAbsenceSet(set);
    handleOpenManageSet();
  };

  const onSaveSet = async ({ name }) => {
    if (absenceSet) {
      await http.patch(api_absence_set(absenceSet.id), { name });
      showSuccessMessage(translations.successMessages.editSet);
      return getAllAbsenceSets(dispatch);
    }

    await http.post(API_ABSENCE_SETS, { name });
    showSuccessMessage(translations.successMessages.addSet);
    return getAllAbsenceSets(dispatch);
  };

  const handleOpenDuplicateSet = set => {
    setAbsenceSet(set);
    setIsDuplicateSetOpened(true);
  };

  const handleCloseDuplicateSet = () => {
    setAbsenceSet(null);
    setIsDuplicateSetOpened(false);
  };

  const onSaveDuplicateSet = async ({ name }) => {
    await http.post(api_absence_set_duplicate(absenceSet.id), { name });
    showSuccessMessage(translations.successMessages.duplicateSet);
    return getAllAbsenceSets(dispatch);
  };

  const handleOpenDeleteSet = set => {
    setAbsenceSet(set);

    if (set?.user_count !== 0) {
      return setIsDeleteSetWarningOpened(true);
    }
    setIsDeleteSetOpened(true);
  };

  const handleCloseDeleteSetDialog = () => {
    setIsDeleteSetOpened(false);
  };

  const handleCloseDeleteSetDialogWarning = () => {
    setIsDeleteSetWarningOpened(false);
    setAbsenceSet(null);
  };

  const onDeleteSet = async () => {
    try {
      await http.delete(api_absence_set(absenceSet.id));
      showSuccessMessage(translations.deleteSetDialog.deleteSuccess);
      getAllAbsenceSets(dispatch);
    } finally {
      setIsDeleteSetOpened(false);
      setAbsenceSet(null);
      setIsManageSetOpened(false);
    }
  };

  const handleGetSetUsers = setId => () => {
    return http.get(api_absence_set_users(setId));
  };

  const handleAssignSetUsers = set => async () => {
    const [allAvailableUsers, assignedSetUsers] = await Promise.all([
      http.get(API_USERS_BASIC, {
        params: {
          ...PEOPLE_DEFAULT_ORDERING,
          [PARAMS.EXCLUDE_ROLE]: ROLES.ASSOCIATE,
          [PARAMS.EXCLUDE_STATUS]: USER_STATUSES.DEACTIVATED,
          [PARAMS.ABSENCE_SET]: set.id,
        },
      }),
      ...(set.user_count !== 0 ? [await handleGetSetUsers(set.id)()] : []),
    ]);

    setAllUsers(
      prepareAssignUserOptions(allAvailableUsers.data, assignedSetUsers?.data)
    );
    setAbsenceSetUsers(prepareAssignedUsers(assignedSetUsers?.data));
    setAbsenceSet(set);
    setIsAssignSetUsersOpened(true);
  };

  const handleCloseAssignSetUsers = () => {
    setAllUsers([]);
    setAbsenceSetUsers([]);
    setAbsenceSet(null);
    setIsAssignSetUsersOpened(false);
  };

  const onSaveSetUsers = async ({ users }) => {
    await http.post(api_absence_set_users(absenceSet.id), {
      users: getObjectToNumberArray(users),
    });
    showSuccessMessage(translations.successMessages.addSetUsers);
    return getAllAbsenceSets(dispatch);
  };

  const handleOpenManageAbsenceType =
    set =>
    (type = null) =>
    e => {
      e.stopPropagation();
      setIsManageAbsenceTypeOpened(true);
      setAbsenceSet(set);
      setAbsenceType(type);
    };

  const handleCloseManageAbsenceType = () => {
    setIsManageAbsenceTypeOpened(false);
    setAbsenceSet(null);
    setAbsenceType(null);
  };

  const onSaveAbsenceType = async ({
    type,
    accrual,
    is_roll_over,
    number_of_days,
    restart_balance_date: expirationDate,
  }) => {
    const hasAdditionalFields = absenceTypeHasAditionalSettingsCheck(type);
    const isVacation = type === ABSENCE_TYPES_KEYS.VACATION_LEAVE;
    const data = {
      type,
      ...(hasAdditionalFields
        ? { accrual, number_of_days: +number_of_days }
        : {}),
      ...(isVacation
        ? {
            is_roll_over,
            restart_balance_date: is_roll_over ? expirationDate : null,
          }
        : {}),
      ...(!absenceType ? { absence_set: absenceSet.id } : {}),
    };

    if (absenceType) {
      handleCloseManageAbsenceType();
      await http.patch(api_absence_type(absenceType.id), data);
      showSuccessMessage(translations.successMessages.editType);
      return getAllAbsenceSets(dispatch);
    }
    handleCloseManageAbsenceType();
    await http.post(API_ABSENCE_TYPES, data);
    showSuccessMessage(translations.successMessages.addType);
    return getAllAbsenceSets(dispatch);
  };

  const handleOpenEditType = type => {
    setIsManageAbsenceTypeOpened(true);
    setAbsenceType(type);
  };

  const handleOpenDeleteAbsenceType = type => {
    setAbsenceType(type);

    if (type.user_count !== 0) {
      return setIsDeleteTypeWarningOpened(true);
    }

    setIsDeleteTypeOpened(true);
  };

  const handleCloseDeleteTypeDialog = () => {
    setIsDeleteTypeOpened(false);
  };

  const handleCloseDeleteTypeWarningDialog = () => {
    setIsDeleteTypeWarningOpened(false);
    setAbsenceType(null);
  };

  const onDeleteType = async () => {
    try {
      await http.delete(api_absence_type(absenceType.id));
      showSuccessMessage(translations.deleteAbsenceTypeDialog.deleteSuccess);
      getAllAbsenceSets(dispatch);
    } finally {
      setIsDeleteTypeOpened(false);
      setAbsenceType(null);
      setIsManageAbsenceTypeOpened(false);
    }
  };

  const handleGetTypeUsers = typeId => () =>
    http.get(api_absence_type_users(typeId));

  const handleAssignTypeUsers = setId => type => async () => {
    const [setUsers, typeUsers] = await Promise.all([
      handleGetSetUsers(setId)(),
      ...(type.user_count !== 0 ? [handleGetTypeUsers(type.id)()] : []),
    ]);

    setAbsenceSetUsers(
      prepareAssignUserOptions(setUsers.data, typeUsers?.data)
    );
    setAbsenceTypeUsers(prepareAssignedUsers(typeUsers?.data));
    setAbsenceType(type);
    setIsAssignTypeUsersOpened(true);
  };

  const handleCloseAssignTypeUsers = () => {
    setAbsenceSetUsers([]);
    setAbsenceTypeUsers([]);
    setAbsenceType(null);
    setIsAssignTypeUsersOpened(false);
  };

  const onSaveTypeUsers = async ({ users }) => {
    await http.post(api_absence_type_users(absenceType.id), {
      users: getObjectToNumberArray(users),
    });
    showSuccessMessage(translations.successMessages.addTypeUsers);
    return getAllAbsenceSets(dispatch);
  };

  const handleOpenManageExtension =
    type =>
    (currentExtension = null) =>
    e => {
      e.stopPropagation();
      setIsManageExtensionOpened(true);
      setAbsenceType(type);
      setExtension(currentExtension);
    };

  const handleCloseManageExtension = () => {
    setIsManageExtensionOpened(false);
    setAbsenceType(null);
    setExtension(null);
  };

  const handleOpenEditExtension = currentExtension => {
    setIsManageExtensionOpened(true);
    setExtension(currentExtension);
  };

  const onSaveExtension = async ({ name, description, number_of_days }) => {
    const data = {
      name,
      description,
      number_of_days: +number_of_days,
      ...(!extension ? { absence: absenceType.id } : {}),
    };

    if (extension) {
      await http.patch(api_absence_extension(extension.id), data);
      showSuccessMessage(translations.successMessages.editExtension);
      return getAllAbsenceSets(dispatch);
    }

    await http.post(API_ABSENCE_EXTENSIONS, data);
    showSuccessMessage(translations.successMessages.addExtension);
    return getAllAbsenceSets(dispatch);
  };

  const handleOpenDeleteExtension = currentExtension => {
    setExtension(currentExtension);

    if (currentExtension.user_count !== 0) {
      return setIsDeleteExtensionWarningOpened(true);
    }

    setIsDeleteExtensionOpened(true);
  };

  const handleCloseDeleteExtensionDialog = () => {
    setIsDeleteExtensionOpened(false);
  };

  const handleCloseDeleteExtensionWarningDialog = () => {
    setIsDeleteExtensionWarningOpened(false);
    setExtension(null);
  };

  const onDeleteExtension = async () => {
    try {
      await http.delete(api_absence_extension(extension.id));
      showSuccessMessage(translations.deleteExtensionDialog.deleteSuccess);
      getAllAbsenceSets(dispatch);
    } finally {
      setIsDeleteExtensionOpened(false);
      setExtension(null);
      setIsManageExtensionOpened(false);
    }
  };

  const handleGetExtensionUsers = extensionId => () =>
    http.get(api_absence_extension_users(extensionId));

  const handleAssignExtensionUsers = typeId => currentExtension => async () => {
    const [typeUsers, extensionAssignedUsers] = await Promise.all([
      handleGetTypeUsers(typeId)(),
      ...(currentExtension.user_count !== 0
        ? [handleGetExtensionUsers(currentExtension.id)()]
        : []),
    ]);
    setAbsenceTypeUsers(typeUsers.data);
    setExtensionUsers(extensionAssignedUsers?.data || []);

    setExtension(currentExtension);
    setIsAssignExtensionUsersOpened(true);
  };

  const handleCloseAssignExtensionUsers = () => {
    setAbsenceTypeUsers([]);
    setExtensionUsers([]);
    setExtension(null);
    setIsAssignExtensionUsersOpened(false);
  };

  const onSaveExtensionUsers = async ({ users }) => {
    await http.post(api_absence_extension_users(extension.id), {
      users: getObjectToNumberArray(users),
    });
    showSuccessMessage(translations.successMessages.addExtensionUsers);
    return getAllAbsenceSets(dispatch);
  };

  const getAbsenceSets = useCallback(async () => {
    try {
      const [users] = await Promise.all([
        http.get(API_USERS_BASIC, {
          params: {
            ...PEOPLE_DEFAULT_ORDERING,
            [PARAMS.EXCLUDE_ROLE]: ROLES.ASSOCIATE,
            [PARAMS.EXCLUDE_STATUS]: USER_STATUSES.DEACTIVATED,
            [PARAMS.ABSENCE_SET]: 1,
          },
        }),
        getAllAbsenceSets(dispatch),
      ]);
      setAllUsers(users.data);
    } finally {
      setIsInitialLoad(false);
    }
  }, [dispatch]);

  const cleanup = useCallback(() => {
    dispatch(clearAllAbsenceSets());
  }, [dispatch]);

  useEffect(() => {
    getAbsenceSets();
  }, [getAbsenceSets]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  return (
    <PageContainer
      {...rest}
      translations={translations}
      navigate={navigate}
      dispatch={dispatch}
      shouldPassProps={false}
      isFullWidthContent
    >
      {!isInitialLoad && (
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.description}>
              <Typography variant="body2">
                {translations.description}
              </Typography>
              <CustomButton
                className={classes.addAttribute}
                type="addRoundedNew"
                onClick={handleOpenManageSet}
              >
                {translations.addSetButton}
              </CustomButton>
            </div>
          </div>
          <AbsenceSets
            translations={translations.absenceSets}
            sets={formatedSets}
            onEditSet={handleOpenEditSet}
            onDuplicateSet={handleOpenDuplicateSet}
            onDeleteSet={handleOpenDeleteSet}
            onGetSetUsers={handleGetSetUsers}
            onAssignSetUsers={handleAssignSetUsers}
            onAddType={handleOpenManageAbsenceType}
            onEditType={handleOpenEditType}
            onDeleteType={handleOpenDeleteAbsenceType}
            onGetTypeUsers={handleGetTypeUsers}
            onAssignTypeUsers={handleAssignTypeUsers}
            onAddExtension={handleOpenManageExtension}
            onEditExtension={handleOpenEditExtension}
            onDeleteExtension={handleOpenDeleteExtension}
            onGetExtensionUsers={handleGetExtensionUsers}
            onAssignExtensionUsers={handleAssignExtensionUsers}
            onAddHoliday={handleOpenManageHoliday}
            onEditHoliday={handleOpenEditHoliday}
            onDeleteHoliday={handleOpenDeleteHoliday}
            onGoToProfilePage={handleGoToUserProfile}
          />
          <CustomFormDrawer
            translations={translations.manageHoliday}
            isOpened={isManageHolidayOpened}
            initialData={manageHolidayInitialData}
            isInitialValid={!!holiday}
            fields={manageHolidayFields}
            onDelete={handleOpenDeleteHoliday}
            onClose={handleCloseManageHoliday}
            onSave={onSaveHoliday}
            hasCancelButton
          />
          <CustomFormDrawer
            translations={translations.manageAbsenceSet}
            isOpened={isManageSetOpened}
            initialData={manageAbsenceSetInitialData}
            isInitialValid={!!absenceSet}
            fields={manageAbsenceSetFields}
            onDelete={handleOpenDeleteSet}
            onClose={handleCloseManageSet}
            onSave={onSaveSet}
            hasCancelButton
          />
          <CustomFormDrawer
            translations={translations.duplicateAbsenceSet}
            isOpened={isDuplicateSetOpened}
            initialData={manageAbsenceSetInitialData}
            fields={manageAbsenceSetFields}
            onClose={handleCloseDuplicateSet}
            onSave={onSaveDuplicateSet}
            hideDelete
            hasCancelButton
          />
          <CustomFormDrawer
            translations={translations.assignSetUsers}
            isOpened={isAssignSetUsersOpened}
            initialData={assignSetUsersInitialData}
            fields={usersField(translations)}
            allUsers={allUsers}
            onClose={handleCloseAssignSetUsers}
            onSave={onSaveSetUsers}
            hasCancelButton
            hideDelete
            isInitialValid
          />
          <CustomFormDrawer
            translations={translations.manageAbsenceType}
            isOpened={isManageAbsenceTypeOpened}
            initialData={manageAbsenceTypeInitialData}
            isInitialValid={!!absenceType}
            fields={manageAbsenceTypeFields}
            absenceTypes={absenceTypes}
            accrualTypes={accrualTypes}
            onDelete={handleOpenDeleteAbsenceType}
            onClose={handleCloseManageAbsenceType}
            onSave={onSaveAbsenceType}
            hasCancelButton
          />
          <CustomFormDrawer
            translations={translations.assignTypeUsers}
            isOpened={isAssignTypeUsersOpened}
            initialData={assignAbsenceTypeUsersInitialData}
            fields={usersField(translations)}
            allUsers={absenceSetUsers}
            onClose={handleCloseAssignTypeUsers}
            onSave={onSaveTypeUsers}
            hasCancelButton
            hideDelete
            isInitialValid
          />
          <CustomFormDrawer
            translations={translations.manageExtension}
            isOpened={isManageExtensionOpened}
            initialData={manageExtensionInitialData}
            isInitialValid={!!extension}
            fields={manageExtensionFields}
            onDelete={handleOpenDeleteExtension}
            onClose={handleCloseManageExtension}
            onSave={onSaveExtension}
            hasCancelButton
          />
          <CustomFormDrawer
            translations={translations.assignExtensionUsers}
            isOpened={isAssignExtensionUsersOpened}
            initialData={assignExtensionUsersInitialData}
            fields={usersField(translations)}
            allUsers={absenceTypeUsers}
            onClose={handleCloseAssignExtensionUsers}
            onSave={onSaveExtensionUsers}
            hasCancelButton
            hideDelete
            isInitialValid
          />
          <AlertDialog
            translations={translations.deleteHolidayDialog}
            isOpened={isDeleteHolidayOpened}
            onClose={handleCloseDeleteHolidayDialog}
            onConfirm={onDeleteHoliday}
            isWarning
          />
          <AlertDialog
            translations={translations.deleteSetDialog}
            isOpened={isDeleteSetOpened}
            onClose={handleCloseDeleteSetDialog}
            onConfirm={onDeleteSet}
            isWarning
          />
          <AlertDialog
            translations={translations.deleteSetDialogWarning}
            isOpened={isDeleteSetWarningOpened}
            onClose={handleCloseDeleteSetDialogWarning}
            isWarning
          />
          <AlertDialog
            translations={translations.deleteAbsenceTypeDialog}
            isOpened={isDeleteTypeOpened}
            onClose={handleCloseDeleteTypeDialog}
            onConfirm={onDeleteType}
            isWarning
          />
          <AlertDialog
            translations={translations.deleteAbsenceTypeWarningDialog}
            isOpened={isDeleteTypeWarningOpened}
            onClose={handleCloseDeleteTypeWarningDialog}
            isWarning
          />
          <AlertDialog
            translations={translations.deleteExtensionDialog}
            isOpened={isDeleteExtensionOpened}
            onClose={handleCloseDeleteExtensionDialog}
            onConfirm={onDeleteExtension}
            isWarning
          />
          <AlertDialog
            translations={translations.deleteExtensionWarningDialog}
            isOpened={isDeleteExtensionWarningOpened}
            onClose={handleCloseDeleteExtensionWarningDialog}
            isWarning
          />
          <NotificationCard
            title={translations.noResults.title}
            content={translations.noResults.content}
            shouldFade={!isInitialLoad && isArrayEmpty(absenceSets)}
          />
        </div>
      )}
    </PageContainer>
  );
};

AbsenceSettingsPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(AbsenceSettingsPage);
